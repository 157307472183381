.footer__col__item {
  font-size: large;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.footer__row_container {
  width: 100%;
}
.footer_container {
  background-color: #162b48;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  min-height: 330px;
  max-width: 100%;
}

.footer__copyright_text {
  font-weight: 400;
}

.footer_link_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-around !important;
  width: 70%;
}

.footer_link {
  text-decoration: none !important;
  color: white !important;
}

.footer__link__address {
  text-decoration: none !important;
  color: white !important;
  text-align: center !important;
}

.footer_link:hover {
  color: #E4232B !important;
}

.donate_button {
  background-color: gold;
  border: none;
  border-radius: 20px;
  color: #162b48;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;;
}

.link_styling {
  text-decoration: none !important;
  color: white !important;
}

.footer__col__item a:-webkit-any-link {
  text-decoration: none !important;
  color: white !important;
  font-family: "Asap", sans-serif;
}
/* 
@media screen and (max-width: 768px) {
  
} */
