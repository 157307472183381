.banner_title {
    color: white;
  font-family: 'Oswald', sans-serif;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  text-align: left;
}

.banner {
  position: relative;
}

.banner-pic {
  background-size: contain;
}

.banner_title_bg {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  bottom: 20%;
  left: 60%;
  padding: 10px 20px;
  width: 30%;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .banner_title_bg {
   display: none !important;
  }
} 
