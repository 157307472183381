.about_banner_title {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 3rem;
  padding-bottom: 15px;
  position: absolute;
  border-bottom: 4px solid #E4232B !important;
}

.about_banner {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.contact_row {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.practice_schedule_container {
  background-color: white;
    border: 1px solid #162b48;
    border-radius: 10px;
    padding: 2rem;

}

.practice_schedule_container h5 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.practice_schedule_container p {
  text-align: left;
}

.form_container {
  background-color: white;
    border: 1px solid #162b48;
    border-radius: 10px;
    flex: 1;
    margin-bottom: 2.5rem;
    padding: 2rem;
}

.form_container h5 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.statement_section {
  background-color: #162b48;
  padding: 2.5em;
}

.statement_section_inverted {
  background-color: white;
}

.transition {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  height: 200px;
  width: 100%;
}