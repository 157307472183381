body {
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  position: relative;
}s

.section-title {
  font-family: 'Oswald', sans-serif;
  font-size: 1rem !important;
  font-weight: 300;
  padding-bottom: 1.5em;
  text-align: center;
}
p {
  font-family: "Asap", sans-serif;
  font-weight: 600;
}

.about-banner-title {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 3rem;
  padding-bottom: 15px;
  position: absolute;
  border-bottom: 4px solid #E4232B !important;


}

.about-banner {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.about-banner-title-container {
  left: 50%;
  border-color: aqua;
  position: absolute;
  text-align: center;
  padding: 10px;
  top: 50%;
  z-index: 2;
}

.banner-title {
    color: white;
  font-family: 'Oswald', sans-serif;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  text-align: left;
}

.banner {
  position: relative;
}

.banner-pic {
  background-size: contain;
}

.carousel-inner {
  max-height: 700px !important;
}
.banner__title-bg {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  bottom: 20%;
  left: 60%;
  padding: 10px 20px;
  width: 30%;
  z-index: 10;
}

/* .button {
  background-color: white;
  border: none;
  border-radius: 20px;
  color: #162b48;
  font-family: "Lato", sans-serif;
  font-size: .75rem;
  font-weight: 600;
  margin-top: 30px;
  padding: 15px 20px;
  text-decoration: none;;
} */

.button:hover {
  background-color: #E4232B;
  border: none;
  color: white;
}

.button-inverted {
  background-color: #162b48;
  color: white;
}

.button-inverted:hover {
  background-color: #E4232B;
  border: none;
  color: white;
}

.container-one {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.map-responsive{
  border-top: 1px solid #162b48;
    overflow:hidden;
    padding-bottom:37%;
    position:relative;
    height: 350px;
}

.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.mission-statement-text {
  font-size: x-large;
  font-style: italic;
}

.nav-link {
  border-bottom: 4px solid transparent !important;
  color: white;
  font-family: 'Oswald', sans-serif !important;
  font-size: 1.15rem;
  margin-left: 3%;
  margin-bottom: -15px;
  padding-bottom: 15px !important;
}

.nav-link:hover {
  color: #E4232B;
  margin-bottom: -15px;
  padding-bottom: 15px !important;
  border-bottom: 4px solid #E4232B !important;
}

.navbar-toggler {
  position: absolute;
  right: 30px;
  top: 14px;
}

.offcanvas {
  background-color: #162b48 !important;
  color: white;
  max-width: 80% !important;
}

.offcanvas-body:hover {
  color: #E4232B;
}

.btn-close {
  background-color: white !important;
  opacity: .75;
}

.pic-container {
  height:auto;
  position: relative;
}

.statement-section {
  background-color: #162b48;
  min-height: 250px;
  padding: 2em;
}

.statement-section-inverted {
  background-color: white;
}

.shadow-container {
  background-color: rgba(0, 0, 0, 0.65);
  float: left;
  height: 100%;
  left: 0;
  position: absolute;
  width: 50%;
}

.statement-container-inverted p {
  color: #162b48;
}

.print-text {
  color: #162b48;
  line-height: 2.5;
  width: 70%;
}


.text-inverted {
  color: white;
}

.print-training-text {
  color: white;
  height: 100%;
  line-height: 2.5rem;
  width: 70%;
  z-index: 10;
}

.print-text-two p {
  margin: 0;
  width: 100%;
}

.team-pic {
  background-size: cover;
  min-height: 60vh;
  position: relative;
}

.transition {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  height: 200px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  h3 {
    font-size: 2rem;
  }
  p {
    font-size: medium;
  }
  .banner-title {
    font-size: 2rem;
  }
  .carousel-inner {
    max-height: 400px !important;
  }
}

@media screen and (max-width: 768px) {
  .banner__title-bg {
   display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .nav-link {
    margin-left: 0;
  }
  .nav-link:hover {
    border-bottom: 4px solid transparent !important;
    margin-left: 0;
  }
}

