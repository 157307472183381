.about_banner_title {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 3rem;
  padding-bottom: 15px;
  position: absolute;
  border-bottom: 4px solid #E4232B !important;
}

.coaches_modal_link {
  border-bottom:2px solid #162b48;
}

.coaches_modal_link:hover {
  border-bottom:2px solid blue;
  color: blue;
  cursor: pointer;
}

.coaches_modal_link p{
  margin: 0;
}

.about_banner {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

.statement_section {
  background-color: #162b48;
  padding: 2.5em;
}

.statement_section_inverted {
  background-color: white;
}

.transition {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  height: 200px;
  width: 100%;
}