* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.custom_nav {
  background-color: rgba(0, 0, 0, 0.35) !important;
  height: 6rem;
  position: absolute;
  top: 30px;
  margin-right: 0;
  /* position: absolute;
  left: 100px;
  width: 100%; */
}

/* .btn-close {
  background-color: white !important;
  opacity: .75;
} */


.offcanvas {
  background-color: #162b48 !important;
  color: white;
  max-width: 80% !important;
}

.nav_container {
  /* position: relative; */
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navbar_link_container {
  display: flex;
  text-decoration: none;
  width: 60%;
}

.navbar_link_container a {
  color: white;
  font-size: 1.15rem;
  text-decoration: none;
}

.navbar_link_container a:hover {
  color: red;
  text-decoration: none;
}

/* .nav-link {
  border-bottom: 4px solid transparent !important;
  color: white;
  font-family: 'Oswald', sans-serif !important;
  font-size: 1.15rem;
  margin-left: 3%;
  margin-bottom: -15px;
  padding-bottom: 15px !important;
}

.nav-link:hover {
  color: #E4232B;
  margin-bottom: -15px;
  padding-bottom: 15px !important;
  border-bottom: 4px solid #E4232B !important;
}

.navbar-toggler {
  position: absolute;
  right: 30px;
  top: 14px;
} */

/* .navbar-toggler-icon {
  background-size: 120%;
  position: absolute;
  right: 30px;
  top: 15px;
} */

.pd_logo {
  z-index: 2;
}

.pd_logo_container {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-left: 2%;
  padding: 5px 20px;
  position: absolute;
  top: -32px;
  
  
  
}

.logo_text h2 {
  color: white;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .pd_logo {
    width: 100px;
    height: auto !important;
  }
  .logo_text h2 {
    font-size: 1.25rem;
  }
  .pd_logo_container {
    top: -22px;
  }
  .custom_nav {
    height: 4rem;
  }
  .nav-link {
    margin-left: 0;
  }
  .nav-link:hover {
    border-bottom: 4px solid transparent !important;
    margin-left: 0;
  }
}
