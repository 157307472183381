h3 {
  font-family: 'Oswald', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  padding-bottom: 1.5em;
  text-align: center;
}
p {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  line-height: 2;
  text-align: center;
}

.button {
  background-color: white;
  border: none;
  border-radius: 20px;
  color: #162b48;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: .75rem;
  font-weight: 600;
  margin-top: 30px;
  padding: 10px 20px;
  text-decoration: none;;
}

.button:hover {
  background-color: #E4232B;
  border: none;
  color: white;
}

.button_inverted {
  background-color: #162b48;
  color: white;
}

.button_inverted:hover {
  background-color: #E4232B;
  border: none;
  color: white;
}

.container_one {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.mission_statement_text {
  font-size: x-large;
  font-style: italic;
}

.pic_container {
  height:auto;
  position: relative;
}

.statement_section {
  background-color: #162b48;
  padding: 2.5em;
}

.statement_section_inverted {
  background-color: white;
}

.shadow_container {
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  height: 100%;
  width: 50%;
}



.statement_section_inverted p {
  color: #162b48;
}

.print_text {
  color: #162b48;
  margin: auto 0;
  width: 90%;
}


.text_inverted {
  color: white;
}

.print_training_text {
  color: white;
  left: 25%;
  margin: auto;
  position: absolute;
  text-align: center;
  top: 30%;
  width: 50%;
  z-index: 10;
}

.print_training_text p {
  line-height: 2.5rem;
}

.print_text_two p {
  margin: 0;
  width: 100%;
}

.team_pic {
position: relative;
}

@media screen and (max-width: 768px) {
  h3 {
    font-size: 2rem;
  }
  p {
    font-size: medium;
  }
  .banner_title {
    font-size: 2rem;
  }
  .carousel_inner {
  max-height: 400px !important;
}
}

.section_title {
  font-family: 'Oswald', sans-serif;
  font-size: 2.5rem !important;
  font-weight: 300;
  padding-bottom: .5em;
  text-align: center;
}

@media screen and (max-width: 512px) {
  .print_training_text {
  color: white;
  left: 10%;
  margin: auto;
  position: absolute;
  text-align: center;
  top: 10%;
  width: 80%;
  z-index: 10;
  }

  .print_training_text p {
    line-height: 2rem;
  }
}